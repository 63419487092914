import React from 'react';
import ReactPlayer from 'react-player/vimeo';

import moduleStyles from './backgroundVideo.module.css';

const BackgroundVideo = ({ url }) => {
  return (
    <div className={moduleStyles.wrapper}>
      <ReactPlayer url={url} playing={true} width="100%" height="100%" muted loop />
    </div>
  );
};

export default BackgroundVideo;
